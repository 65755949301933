"use client";

import { useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";

// modal's contents are returned by a function that receives the close function as an argument
export default function Modal({
  content,
  children,
}: {
  content: (close: () => void) => React.ReactNode;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  // make body not scrollable when modal is open
  // we can't set this class directly on the <body> element without
  // blocking server-side rendering of the entire layout.
  useEffect(() => {
    const body = document.querySelector("body");
    if (!body) {
      return;
    }
    if (isOpen) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  const close = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div
          className={[
            "fixed inset-0 p-4 min-h-dvh w-full", // fullscreen
            "flex items-center justify-center", // center children
            "z-40 bg-black bg-opacity-20 backdrop-blur-[3px]", // fade background
          ].join(" ")}
          onClick={close}
        >
          <div
            className="bg-white px-4 py-3 rounded-lg shadow-lg relative"
            onClick={(e) => e.stopPropagation()} // avoid closing when clicking inside the modal
          >
            <button
              className="absolute rounded-full bg-white text-gray-500 shadow right-0 top-0 mr-[-0.75rem] mt-[-0.75rem] p-1 z-10"
              type="button"
              onClick={close}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
            <div className="max-h-[80dvh] overflow-y-auto">
              {content(close)}
            </div>
          </div>
        </div>
      )}
      {/* click target for the modal is passed in children */}
      <div onClick={() => setIsOpen(true)}>{children}</div>
    </>
  );
}
