"use client";

import type { UserEntity } from "@/hl-common/types/api/entities/Users";
import { CourseContext } from "@/utils/CourseContext";
import Modal from "@/utils/Modal";
import { ModuleContext } from "@/utils/module/ModuleContext";
import { useForm } from "@/utils/useForm";
import { ArrowRightIcon, FlagIcon } from "@heroicons/react/20/solid";
import * as Sentry from "@sentry/browser";
import { useContext, useState } from "react";
import Message from "../Message";
import { Button } from "../forms/Button";
import Input from "../forms/Input";
import TextArea from "../forms/TextArea";

export const FeedbackButton = ({ user }: { user: UserEntity }) => {
  return (
    <Modal
      content={(closeModal) => (
        <FeedbackForm user={user} closeModal={closeModal} />
      )}
    >
      <Button>
        <span>Report an issue</span>{" "}
        <FlagIcon className="inline ml-1 h-5 w-5" />
      </Button>
    </Modal>
  );
};

export const FeedbackForm = ({
  user,
  closeModal,
}: {
  user: UserEntity | null | undefined;
  closeModal?: () => void;
}) => {
  const { card, module } = useContext(ModuleContext);
  const { course } = useContext(CourseContext);
  const [showThanks, setShowThanks] = useState(false);
  const sentry = Sentry.getCurrentHub().getClient();

  const { handleSubmit, handleInputChange, fields } = useForm(
    {
      event_id: "",
      name: makeName(user),
      email: user?.email,
      comments: "",
    },
    (feedback) => {
      if (feedback.comments) {
        const formattedContext = JSON.stringify(
          {
            courseId: course?.id,
            moduleId: module?.id,
            cardId: card?.id,
          },
          null,
          2,
        );

        feedback.comments += `\n\nUser context: ${formattedContext}`;
        feedback.event_id = Sentry.captureMessage("User Feedback");

        Sentry.captureUserFeedback(feedback);
        setShowThanks(true);
      } else {
        closeModal?.();
      }
    },
  );

  const gaveContactInfo = fields.name && fields.name !== anon;

  if (showThanks) {
    return (
      <div className="min-w-80">
        <p className="mb-2">
          Thank you for your feedback!
          {gaveContactInfo && (
            <>
              <br />
              We may be in touch with regards to this issue.
            </>
          )}
        </p>
        {closeModal && (
          <Button secondary onClick={closeModal}>
            Close
          </Button>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {!sentry && (
        <Message warning>
          Sentry is not initialized. Feedback will not be submitted.
        </Message>
      )}
      {/* give anonymous users the chance to include contact details */}
      {!user?.id && (
        <Input
          id="name"
          name="name"
          label="Contact info"
          required
          placeholder="WhatsApp phone number, email, etc."
          onChange={handleInputChange}
        />
      )}
      <TextArea
        id="comments"
        name="comments"
        autoFocus
        label="Report a problem"
        rows={6}
        cols={40}
        minLength={20}
        onChange={handleInputChange}
        placeholder="What went wrong? How can we improve?"
      />
      <Button type="submit">
        Submit Feedback <ArrowRightIcon className="inline ml-1 w-5 h-5" />
      </Button>
    </form>
  );
};

const anon = "Anonymous";

const makeName = (user: UserEntity | null | undefined) => {
  if (!user?.id) {
    return anon;
  }

  return [user.first, user.last, `(${user.id})`].filter(Boolean).join(" ");
};
