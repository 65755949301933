import type React from "react";

import classNames from "classnames";

import { Field } from "./Field";

export default function TextArea({
  className,
  label,
  description,
  ...rest
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  description?: string;
}) {
  return (
    <Field label={label} htmlFor={rest.id} description={description}>
      <textarea
        className={classNames(
          className,
          "block px-3 py-2 w-full rounded bg-[#F8F8F8] border-[#DFE2E4] border shadow-inner", // default classes
          {
            "mb-4": !description,
            "mb-1": description,
          },
        )}
        {...rest}
      />
    </Field>
  );
}
